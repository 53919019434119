<template>
  <div>
    <v-textarea
      :disabled="loading"
      solo
      v-model="note"
      name="input-7-4"
      label="Add note here."
    ></v-textarea>
    <v-btn v-if="!loading" dark @click="submitNote">Add Note</v-btn>
    <v-btn v-if="!loading" dark @click="closeNote" class="ml-4">Cancel</v-btn>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-circular>
    <br />
    <br />
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  props: ["lenderId", "dealId", "lenderContactId", "insertToTable"],
  data() {
    return {
      note: "",
      loading: false,
    };
  },
  methods: {
    async submitNote() {
      try {
        this.loading = true;
        let authUser = this.$store.getters["Auth/getAuthUser"];

        if (this.insertToTable == "lender") {
          let insertedNote = await API().post("/lenderNote", {
            lenderId: this.lenderId,
            note: this.note,
            createdById: authUser.id,
          });
        }

        if (this.insertToTable == "deal") {
          console.log('deal id being inserted is ' + this.dealId)
          let insertedNote = await API().post("/dealNote", {
            dealId: this.dealId,
            note: this.note,
            createdById: authUser.id,
          });
        }

        if (this.insertToTable == "lenderContact") {
          console.log('lendercontactid to be inserted is ' + this.lenderContactId)
          let insertedNote = await API().post("/lenderContactNote", {
            lenderContactId: this.lenderContactId,
            note: this.note,
            createdById: authUser.id,
          });
        }

        this.$emit("refreshNotes");
      } catch (e) {
        console.log(e);
      }
    },
    closeNote() {
      this.$emit("closeNote");
    },
  },
};
</script>

<style>
</style>